<template>
  <div class="pricing-conversao-container" v-bind:class="rootClassName">
    <div class="pricing-conversao-container1">
      <div class="pricing-conversao-container2">
        <DangerousHTML
          html="<script async src='https://js.stripe.com/v3/pricing-table.js'></script>
  <stripe-pricing-table pricing-table-id='prctbl_1Nqb75F0soJNd21DUhDqhqtB'
  publishable-key='pk_live_51NFhQeF0soJNd21DV68SkNvL99MtKrqrGUu6K7YZ16P0aIhFrzx4ug9nokTENDG6fTc0W9QA5DeZt7mhUQ5FHRRg00tdQejsDM'>
  </stripe-pricing-table>"
        ></DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

export default {
  name: 'PricingConversao',
  props: {
    rootClassName: String,
  },
  components: {
    DangerousHTML,
  },
}
</script>

<style scoped>
.pricing-conversao-container {
  width: 39rem;
  height: 40rem;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  margin-top: 6px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.pricing-conversao-container1 {
  width: 100%;
  height: 100%;
}
.pricing-conversao-container2 {
  display: contents;
}
.pricing-conversao-root-class-name {
  height: auto;
  margin: 1%;
  align-self: stretch;
}
</style>
