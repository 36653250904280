<template>
  <div class="landing-container">
    <div>
      <div class="landing-container02">
        <DangerousHTML html="<!-- Google tag (gtag.js) -->
  <script async src='https://www.googletagmanager.com/gtag/js?id=AW-11333416159'></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'AW-11333416159');
  </script>"></DangerousHTML>
      </div>
    </div>
    <app-header rootClassName="header-root-class-name2"></app-header>
    <div class="landing-hero">
      <div class="container">
        <div class="textScrambler-container">
          <div class="scrambleText"></div>
        </div>
      </div>
      <span class="landing-text03">
        <span>
          🌎 Nossas aulas combinam aprendizado de vocabulário, frases essenciais e
          MUITA prática de conversação!
          <span v-html="rawdrjx"></span>
        </span>
        <br />
        <span>
          Não perca mais tempo. Comece sua jornada rumo à fluência em inglês
          conosco.
          <span v-html="rawzbrr"></span>
        </span>
        <br />
        <span>
          Inscreva-se hoje e comece a falar inglês desde a primeira aula. 📢✨
        </span>
        <span><span v-html="rawjo36"></span></span>
      </span>
      <div class="landing-btn-group">
        <a href="#Start" class="landing-link button">Saiba Mais</a>
        <a href="https://contact.sailthrough.com.br" target="_blank" rel="noreferrer noopener"
          class="landing-link1 button">
          <span class="landing-text10">
            <span>Eu QUERO!</span>
            <br />
          </span>
        </a>
      </div>
    </div>
    <div id="Start" class="landing-stats">
      <div class="landing-stat">
        <h1 class="landing-text13">
          <span>100</span>
          <span>+</span>
        </h1>
        <span class="landing-text16">Alunos Satisfeitos</span>
        <span class="landing-text17">
          Pode perguntar pra eles, você não vai encontrar um navio em melhor
          ordem.
        </span>
      </div>
      <div class="landing-stat1">
        <h1 class="landing-text18">5+</h1>
        <span class="landing-text19">Países</span>
        <span class="landing-text20">
          Cobrimos qualquer fronteira, god bless the internet
        </span>
      </div>
      <div class="landing-stat2">
        <h1 class="landing-text21">
          <span>5</span>
          <span>+</span>
        </h1>
        <span class="landing-text24">Anos de Experiência</span>
        <span class="landing-text25">
          Professores formados e qualificados, prontos para guiar sua jornada.
        </span>
      </div>
      <div class="landing-stat3">
        <h1 class="landing-text26"><span>24/7</span></h1>
        <span class="landing-text28">Suporte</span>
        <span class="landing-text29">
          Em dias de calmaria ou tempestade, we got you!
        </span>
      </div>
    </div>
    <div class="landing-features">
      <h1 class="landing-text30">Aulas de Inglês</h1>
      <div class="landing-separator"></div>
      <h1 class="landing-text31">
        <span class="landing-text32">
          Quer começar a falar inglês? Comece desde sua primeira aula
        </span>
        <span class="landing-text33">
          . Nos combinamos aprendizado de vocabulário, frases essenciais e
          gramática com muita prática de
          <span v-html="raw2ubz"></span>
        </span>
        <span class="landing-text34">conversação real com colegas</span>
        <span class="landing-text35">.</span>
      </h1>
      <div class="landing-container03">
        <img alt="image" src="https://imgur.com/gd0TFMW.jpg" class="landing-image" />
        <div class="landing-container04">
          <feature-card42 text="📖" title="Aprendizado de Vocabulário"
            description="Construa um rico vocabulário que o capacitará a se comunicar com confiança."
            rootClassName="rootClassName1"></feature-card42>
          <feature-card42 text="💬" title="Frases e Expressões Essenciais"
            description="Domine as expressões que fazem a diferença na comunicação diária."
            rootClassName="rootClassName4"></feature-card42>
          <feature-card42 text="📚" title="Gramática Prática"
            description="Aprenda a estrutura da língua de forma prática e aplicável."
            rootClassName="rootClassName3"></feature-card42>
          <feature-card42 text="🗣️" title="Interação com Colegas"
            description="Pratique suas habilidades de conversação em um ambiente acolhedor e colaborativo."
            rootClassName="rootClassName9"></feature-card42>
          <feature-card42 text="🎥" title="Aulas Gravadas para Revisão"
            description="Nunca perca uma lição com acesso às aulas gravadas."
            rootClassName="rootClassName2"></feature-card42>
          <feature-card42 text="📘" title="Ebook para Aprofundar o Conhecimento"
            description="Reforce seu aprendizado com materiais de apoio exclusivos."
            rootClassName="rootClassName10"></feature-card42>
          <feature-card42 text="👥" title="Turmas Pequenas e Atenção Individualizada"
            description="Receba a atenção pessoal de que você precisa para alcançar seus objetivos."
            rootClassName="rootClassName11"></feature-card42>
          <feature-card42 text="🔊" title="Encontros Mensais Individuais"
            description="Afine sua pronúncia e tire todas as suas dúvidas com encontros particulares comigo."
            rootClassName="rootClassName12"></feature-card42>
        </div>
      </div>
      <div class="landing-container05">
        <div class="landing-container06">
          <span class="landing-text36">De €120/mês</span>
          <h1 class="landing-text37">Por €75/mês</h1>
        </div>
        <a href="https://contact.sailthrough.com.br" target="_blank" rel="noreferrer noopener"
          class="landing-link2 button">
          Eu Quero!
        </a>
      </div>
    </div>
    <div class="landing-features1">
      <h1 class="landing-text38">Aulas de Conversação</h1>
      <div class="landing-separator1"></div>
      <h1 class="landing-text39">
        <span class="landing-text40">
          Se você já possui conhecimento em inglês, mas
          <span v-html="rawv7e7"></span>
        </span>
        <span class="landing-text41">
          sente que falta confiança ou se sente desconfortável
          <span v-html="rawb6nv"></span>
        </span>
        <span class="landing-text42">ao falar</span>
        <span class="landing-text43">
          ,
          <span v-html="raw4har"></span>
        </span>
        <span class="landing-text44">
          nossas turmas de conversação são perfeitas para você
        </span>
        <span class="landing-text45">
          .
          <span v-html="rawzbao"></span>
        </span>
      </h1>
      <div class="landing-container07">
        <div class="landing-container08">
          <feature-card42 text="🗣️ " title="Encontros 100% em Inglês"
            description="Imersão total para elevar sua fluência e autoconfiança."
            rootClassName="rootClassName5"></feature-card42>
          <feature-card42 text="🌐" title="Tópicos Diversificados para Conversa"
            description="De cultura a atualidades, abordamos uma ampla gama de temas."
            rootClassName="rootClassName6"></feature-card42>
          <feature-card42 text="🎥" title="Aulas Gravadas"
            description="Revise e aprimore suas habilidades assistindo às aulas gravadas quando quiser."
            rootClassName="rootClassName7"></feature-card42>
          <feature-card42 text="📺" title="Legendas ao Vivo"
            description="Acesso a legendas em tempo real para facilitar a compreensão."
            rootClassName="rootClassName14"></feature-card42>
          <feature-card42 text="📚" title="Aquisição de Frases e Expressões Idiomáticas"
            description="Reforce sua capacidade de se expressar com expressões autênticas."
            rootClassName="rootClassName8"></feature-card42>
          <feature-card42 text="👥" title="Turmas com até 8 Alunos"
            description="Ambiente intimista para prática eficaz e atenção personalizada."
            rootClassName="rootClassName13"></feature-card42>
        </div>
        <img alt="image" src="https://imgur.com/PNmPApn.jpeg" class="landing-image1" />
      </div>
      <div class="landing-container09">
        <div class="landing-container10">
          <span class="landing-text46">De €80/mês</span>
          <h1 class="landing-text47">Por €40/mês</h1>
        </div>
        <a href="https://contact.sailthrough.com.br" target="_blank" rel="noreferrer noopener"
          class="landing-link3 button">
          Eu Quero!
        </a>
      </div>
    </div>
    <div class="landing-testimonial">
      <div class="landing-container11">
        <div class="landing-container12">
          <div class="landing-container13">
            <testimonial-card21 name="Allan Gomes"
              quote="I have been studying with Thais for 5 months and I can say that she is an exceptional professional. She stands out for her impeccable teaching, punctuality, organization and ability to transmit content clearly and objectively."
              profileSrc="https://imgur.com/U2EV3vU.jpg" rootClassName="rootClassName1"></testimonial-card21>
          </div>
          <testimonial-card21 name="Sara Montês"
            quote="As aulas são muito interativas e ajustam-se às nossas necessidades! Comecei com o inglês do 0 e sinto que evoluí bastante. Estas sessões têm me ajudado a ganhar confiança e à vontade na língua. Recomendo!"
            profileSrc="https://images.unsplash.com/photo-1542909192-2f2241a99c9d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHBvcnRyYWl0JTIwYnd8ZW58MHx8fHwxNjI2NDUyMjQw&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName2"></testimonial-card21>
        </div>
        <div class="landing-container14">
          <testimonial-card41 name="Julia Santos" role="Inglês A1"
            quote="Muito boa a aula. Material excelente e professora muito legal. Tô conseguindo evoluir muito tanto na gramática quanto na pronúncia"
            pictureSrc="https://imgur.com/U2EV3vU.jpg" profileAlt="Julia Santos"
            profileSrc="https://images.unsplash.com/photo-1553184118-d20774c4c1db?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxwb3J0cmFpdCUyMGJ3fGVufDB8fHx8MTYyNjQ1MjI0MA&amp;ixlib=rb-1.2.1&amp;h=1200"
            rootClassName="rootClassName"></testimonial-card41>
        </div>
      </div>
    </div>
    <div class="landing-banner">
      <h1 class="landing-text48">Vem navegar com a gente!</h1>
      <span class="landing-text49">
        Aproveita enquanto ainda temos vagas! As turmas tem tamanhos limitados
        para garantir a qualidade de ensino.
      </span>
      <div class="landing-container15">
        <a href="https://contact.sailthrough.com.br" target="_blank" rel="noreferrer noopener"
          class="landing-link4 button">
          Eu Quero!
        </a>
      </div>
    </div>
    <footer-gray2 rootClassName="footer-gray2-root-class-name"></footer-gray2>
    <footer-gray3 rootClassName="footer-gray3-root-class-name2"></footer-gray3>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

import AppHeader from '../components/header'
import FeatureCard42 from '../components/feature-card42'
import TestimonialCard21 from '../components/testimonial-card21'
import TestimonialCard41 from '../components/testimonial-card41'
import FooterGray2 from '../components/footer-gray2'
import FooterGray3 from '../components/footer-gray3'
import chatWoo from '../components/chatWoo';


class TextScramble {
  constructor(el) {
    this.el = el;
    this.chars = 'ThisIsAGarbledMEss';
    this.update = this.update.bind(this);
  }
  setText(newText) {
    const oldText = this.el.innerText;
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise((resolve) => this.resolve = resolve);
    this.queue = [];
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || '';
      const to = newText[i] || '';
      const start = Math.floor(Math.random() * 80);
      const end = start + Math.floor(Math.random() * 80);
      this.queue.push({ from, to, start, end });
    }
    cancelAnimationFrame(this.frameRequest);
    this.frame = 0;
    this.update();
    return promise;
  }
  update() {
    let output = '';
    let complete = 0;
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i];
      if (this.frame >= end) {
        complete++;
        output += to;
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar();
          this.queue[i].char = char;
        }
        output += `<span class="dud">${char}</span>`;
      } else {
        output += from;
      }
    }
    this.el.innerHTML = output;
    if (complete === this.queue.length) {
      this.resolve();
    } else {
      this.frameRequest = requestAnimationFrame(this.update);
      this.frame++;
    }
  }
  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }
  highlightWords(words, colorClass) {
    const text = this.el.innerText;
    words.forEach(word => {
      const regex = new RegExp(`\\b${word}\\b`, 'g');
      const replacement = `<span class="${colorClass}">${word}</span>`;
      this.el.innerHTML = text.replace(regex, replacement);
    });
  }
}

export default {
  name: 'Landing',
  props: {},
  components: {
    DangerousHTML,
    AppHeader,
    FeatureCard42,
    TestimonialCard21,
    TestimonialCard41,
    FooterGray2,
    FooterGray3,
    chatWoo,
  },
  data() {
    return {
      textScramble: null,
      phrases: [
        'Do you want to speak English?',
        'Você quer falar inglês?',
        'In your first lesson?',
        'Na sua primeira aula?',
        'SailThrough',
      ],
      counter: 0,
      rawdrjx: ' ',
      rawzbrr: ' ',
      rawjo36: ' ',
      raw2ubz: ' ',
      rawv7e7: ' ',
      rawb6nv: ' ',
      raw4har: ' ',
      rawzbao: ' ',
    }
  },
  metaInfo: {
    title: 'Landing - SailThrough English',
    meta: [
      {
        property: 'og:title',
        content: 'Landing - SailThrough English',
      },
      {
        property: 'og:description',
        content: 'Where English is a Breeze.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/09f000e7-ab0d-4836-ba24-3a9b40291860/1d2dd100-6c7e-4c7d-b25d-b8a45b16c37d?org_if_sml=1&force_format=original',
      },
    ],
  },
  mounted() {
    const el = this.$el.querySelector('.scrambleText');
    this.textScramble = new TextScramble(el);
    this.next();
  },
  methods: {
    next() {
      this.textScramble.setText(this.phrases[this.counter]).then(() => {
        this.textScramble.highlightWords(['English', 'Inglês', 'first lesson', 'primeira aula'], 'highlight');
        setTimeout(this.next, 800);
      });
      this.counter = (this.counter + 1) % this.phrases.length;
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:100');

html,
body {
  font-family: 'Roboto Mono', monospace;
  background: #212121;
  height: 100%;
}

#countdown {
  font-weight: 500;
  font-size: 24px;
  height: 120%;
  width: 120%;
  color: #ffc53f;
  justify-content: center;
  align-items: center;
  display: flex;
}

.scrambleText {
  font-weight: 700;
  font-size: 28px;
  font-style: bold;
  color: #F2F2F2;
  text-align: center;
}

.textScrambler-container {
  background: rgba(0, 0, 0, 0.2);
  height: 100px;
  /* Adjust this value based on your needs */
  width: 330px;
  /* Adjust this value based on your needs */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: monospace;
  overflow: hidden;
}

.highlight {
  color: #f1b320;
}

.landing-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}

.landing-container02 {
  display: contents;
}

.landing-hero {
  width: 100%;
  display: flex;
  padding: 48px;
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(113deg, rgba(178, 53, 64, 0.8) 6.00%, rgba(161, 113, 46, 0.8) 94.00%), url('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwallpaperboat.com%2Fwp-content%2Fuploads%2F2021%2F03%2F15%2F70899%2Fanime-beach-25.jpg&f=1&nofb=1&ipt=a456914996d88d559d03fa0c9404620906a38001e69b717adc92dd04822eeafd&ipo=images');
}

.landing-text {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
  align-self: center;
  text-align: center;
}

.landing-text02 {
  color: #fff566;
  font-size: 4rem;
  font-weight: 900;
}

.landing-text03 {
  color: var(--dl-color-gray-white);
  align-self: center;
  margin-top: 32px;
  text-align: center;
  margin-bottom: 32px;
}

.landing-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.landing-link {
  color: var(--dl-color-gray-white);
  font-size: 0.75rem;
  transition: 0.3s;
  font-weight: bold;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
}

.landing-link:hover {
  transform: scale(1.02);
}

.landing-link1 {
  font-size: 0.75rem;
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
}

.landing-link1:hover {
  transform: scale(1.02);
}

.landing-text10 {
  font-weight: bold;
}

.landing-stats {
  width: 100%;
  display: grid;
  padding: 48px;
  grid-gap: 32px;
  max-width: 1400px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.landing-stat {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landing-text13 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}

.landing-text16 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-text17 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-stat1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landing-text18 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}

.landing-text19 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-text20 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-stat2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landing-text21 {
  color: rgb(255, 124, 76);
  font-size: 3rem;
}

.landing-text24 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-text25 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-stat3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.landing-text26 {
  color: #FF7C4C;
  font-size: 3rem;
}

.landing-text28 {
  font-size: 1.15rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-text29 {
  color: var(--dl-color-gray-500);
  font-size: 0.75rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}

.landing-features {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  flex-direction: column;
}

.landing-text30 {
  font-size: 3rem;
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}

.landing-separator {
  height: 2px;
  align-self: stretch;
  background-color: var(--dl-color-gray-500);
}

.landing-text31 {
  font-size: 2rem;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-weight: 300;
  margin-bottom: 32px;
}

.landing-text32 {
  font-weight: 700;
}

.landing-text33 {
  font-style: normal;
}

.landing-text34 {
  font-weight: 700;
}

.landing-text35 {
  font-style: normal;
}

.landing-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 32px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.landing-image {
  width: 450px;
  height: 450px;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  object-position: left;
}

.landing-container04 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.landing-container05 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.landing-container06 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.landing-text36 {
  display: none;
  font-weight: 600;
  text-transform: capitalize;
}

.landing-text37 {
  align-self: flex-start;
  text-align: left;
}

.landing-link2 {
  color: #000000;
  width: auto;
  font-size: 2rem;
  align-self: flex-end;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  transition: 0.3s;
  font-weight: bold;
  margin-left: var(--dl-space-space-halfunit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius1);
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(135deg, rgb(254, 172, 94) 0.00%, rgba(199, 121, 208, 0.75) 100.00%);
}

.landing-link2:hover {
  font-size: 2.1rem;
}

.landing-features1 {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  flex-direction: column;
}

.landing-text38 {
  color: rgba(103, 116, 142, 0.95);
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-unit);
}

.landing-separator1 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 2px;
  align-self: stretch;
  background-color: var(--dl-color-gray-500);
}

.landing-text39 {
  font-size: 2rem;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-weight: 300;
  margin-bottom: 32px;
}

.landing-text40 {
  font-style: normal;
}

.landing-text41 {
  font-weight: 700;
}

.landing-text42 {
  font-style: normal;
  font-weight: 300;
}

.landing-text43 {
  font-style: normal;
}

.landing-text44 {
  font-weight: 700;
}

.landing-text45 {
  font-style: normal;
}

.landing-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 32px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.landing-container08 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.landing-image1 {
  width: 450px;
  height: 450px;
  object-fit: cover;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
  object-position: left;
}

.landing-container09 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.landing-container10 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.landing-text46 {
  display: none;
  font-weight: 600;
  text-transform: capitalize;
}

.landing-text47 {
  align-self: flex-start;
  text-align: left;
}

.landing-link3 {
  color: #000000;
  width: auto;
  font-size: 2rem;
  align-self: flex-end;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  transition: 0.3s;
  font-weight: bold;
  margin-left: var(--dl-space-space-halfunit);
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  border-radius: var(--dl-radius-radius-radius1);
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(135deg, rgb(254, 172, 94) 0.00%, rgba(199, 121, 208, 0.75) 100.00%);
}

.landing-link3:hover {
  font-size: 2.1rem;
}

.landing-testimonial {
  width: 100%;
  display: flex;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(45deg, rgba(53, 178, 138, 0.59) 22.00%, rgba(217, 151, 65, 0.8) 95.00%), url('https://imgur.com/XMtfr0L.jpg');
  background-position: center;
}

.landing-container11 {
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: stretch;
  justify-content: space-between;
}

.landing-container12 {
  flex: 2;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-right: 32px;
  flex-direction: column;
  justify-content: flex-start;
}

.landing-container13 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
  flex-direction: column;
  justify-content: flex-start;
}

.landing-container14 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 48px;
  padding-right: 48px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-image: url('https://images.unsplash.com/photo-1485628390555-1a7bd503f9fe?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxhcmNoaXRlY3R1cmUlMjBtaW5pbWFsfGVufDB8fHx8MTYyNjE4NDA0Mg&ixlib=rb-1.2.1&w=1500');
  background-position: center;
}

.landing-text48 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}

.landing-text49 {
  max-width: 1400px;
  text-align: center;
  margin-bottom: 32px;
}

.landing-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.landing-link4 {
  color: #000000;
  width: auto;
  font-size: 2rem;
  align-self: flex-start;
  text-align: center;
  transition: 0.3s;
  font-weight: bold;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius1);
  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(135deg, rgb(254, 172, 94) 0.00%, rgba(199, 121, 208, 0.75) 100.00%);
}

.landing-link4:hover {
  font-size: 2.1rem;
}

@media(max-width: 991px) {
  .landing-hero {
    flex-direction: column;
  }

  .landing-text {
    text-align: center;
  }

  .landing-text03 {
    text-align: center;
  }

  .landing-text13 {
    text-align: center;
  }

  .landing-text18 {
    text-align: center;
  }

  .landing-text21 {
    text-align: center;
  }

  .landing-text26 {
    text-align: center;
  }

  .landing-features {
    align-items: center;
  }

  .landing-text30 {
    align-self: center;
  }

  .landing-separator {
    align-self: center;
  }

  .landing-text31 {
    align-self: center;
  }

  .landing-container03 {
    flex-direction: column;
  }

  .landing-image {
    width: 300px;
    height: 300px;
    display: none;
    margin-top: 32px;
    margin-left: 0px;
  }

  .landing-link2 {
    align-self: center;
    margin-top: var(--dl-space-space-unit);
  }

  .landing-features1 {
    align-items: center;
  }

  .landing-text38 {
    display: flex;
  }

  .landing-text39 {
    align-self: center;
  }

  .landing-container07 {
    flex-direction: column;
  }

  .landing-image1 {
    width: 300px;
    height: 300px;
    display: none;
    margin-top: 32px;
    margin-left: 0px;
  }

  .landing-link3 {
    align-self: center;
    margin-top: var(--dl-space-space-unit);
  }

  .landing-container11 {
    flex-direction: column;
  }

  .landing-container12 {
    margin-right: 0px;
    margin-bottom: 32px;
  }

  .landing-text49 {
    width: 100%;
  }

  .landing-link4 {
    align-self: center;
  }
}

@media(max-width: 767px) {
  .landing-hero {
    padding-left: 32px;
    padding-right: 32px;
  }

  .landing-link {
    display: none;
  }

  .landing-stats {
    padding-left: 32px;
    padding-right: 32px;
    grid-template-columns: 1fr 1fr;
  }

  .landing-text16 {
    text-align: center;
  }

  .landing-text19 {
    text-align: center;
  }

  .landing-text24 {
    text-align: center;
  }

  .landing-text28 {
    text-align: center;
  }

  .landing-features {
    padding-left: 32px;
    padding-right: 32px;
  }

  .landing-text30 {
    text-align: center;
  }

  .landing-text31 {
    font-size: 1.75rem;
    font-style: normal;
    text-align: center;
    font-weight: 300;
  }

  .landing-container03 {
    flex-direction: column;
  }

  .landing-features1 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .landing-text38 {
    text-align: center;
  }

  .landing-text39 {
    font-size: 1.75rem;
    text-align: center;
  }

  .landing-container07 {
    flex-direction: column;
  }

  .landing-container11 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .landing-banner {
    padding-left: 32px;
    padding-right: 32px;
  }

  .landing-text49 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}

@media(max-width: 479px) {
  .landing-hero {
    width: 100%;
    padding: 32px;
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }

  .landing-btn-group {
    flex-direction: column;
  }

  .landing-stats {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
    grid-template-columns: 1fr;
  }

  .landing-features {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .landing-text30 {
    font-size: 3rem;
    align-self: center;
  }

  .landing-separator {
    align-self: center;
  }

  .landing-text31 {
    font-size: 1.75rem;
    align-self: center;
  }

  .landing-image {
    width: 250px;
    height: 250px;
  }

  .landing-container04 {
    grid-template-columns: 1fr;
  }

  .landing-container05 {
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  .landing-container06 {
    width: auto;
    align-self: stretch;
    align-items: stretch;
    justify-content: space-between;
  }

  .landing-text37 {
    align-self: center;
    text-align: center;
  }

  .landing-link2 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .landing-features1 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .landing-text38 {
    font-size: 3rem;
  }

  .landing-text39 {
    font-size: 1.75rem;
    align-self: center;
  }

  .landing-container08 {
    grid-template-columns: 1fr;
  }

  .landing-image1 {
    width: 250px;
    height: 250px;
  }

  .landing-container09 {
    align-self: center;
  }

  .landing-link3 {
    margin-top: var(--dl-space-space-halfunit);
  }

  .landing-container11 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .landing-banner {
    padding-top: 48px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 48px;
  }

  .landing-container15 {
    align-items: center;
    flex-direction: column;
  }

  .landing-link4 {
    padding: var(--dl-space-space-halfunit);
    margin-top: var(--dl-space-space-unit);
  }
}
</style>
