<template>
  <div class="pricing-container">
    <app-header rootClassName="header-root-class-name1"></app-header>
    <div class="pricing-container1"></div>
    <div class="pricing-container2">
      <pricing-conversao
        rootClassName="pricing-conversao-root-class-name"
      ></pricing-conversao>
      <pricing-aulas
        rootClassName="pricing-aulas-root-class-name"
      ></pricing-aulas>
    </div>
    <footer-gray3 rootClassName="footer-gray3-root-class-name3"></footer-gray3>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import PricingConversao from '../components/pricing-conversao'
import PricingAulas from '../components/pricing-aulas'
import FooterGray3 from '../components/footer-gray3'

export default {
  name: 'Pricing',
  props: {},
  components: {
    AppHeader,
    PricingConversao,
    PricingAulas,
    FooterGray3,
  },
  metaInfo: {
    title: 'Pricing - SailThrough English',
    meta: [
      {
        property: 'og:title',
        content: 'Pricing - SailThrough English',
      },
      {
        property: 'og:description',
        content: 'Where English is a Breeze.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/09f000e7-ab0d-4836-ba24-3a9b40291860/1d2dd100-6c7e-4c7d-b25d-b8a45b16c37d?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.pricing-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.pricing-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.pricing-container2 {
  width: 100%;
  height: 815px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
@media(max-width: 1200px) {
  .pricing-container1 {
    border-width: 0px;
  }
}
@media(max-width: 991px) {
  .pricing-container1 {
    z-index: 30;
  }
  .pricing-container2 {
    z-index: 30;
  }
}
</style>
