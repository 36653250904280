<template>
    <div class="newsletter-page">
        <Header />
        <div id="newsletter-signup"
            style="min-height: 58px; max-width: 440px; width: 100%; position: relative; margin: 0 auto 30px; padding-top: 200px;">
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/header.vue';
import Footer from '../components/footer-gray3.vue';

export default {
    name: 'NewsletterPage',
    components: {
        Header,
        Footer
    },
    mounted() {
        this.loadNewsletterSignupScript();
    },
    methods: {
        loadNewsletterSignupScript() {
            const container = document.getElementById('newsletter-signup');
            if (!container) return;
            container.innerHTML = '';
            const div = document.createElement('div');
            div.style.height = '40vh';
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js';
            script.dataset.backgroundColor = "#ffffff";
            script.dataset.textColor = "#000000";
            script.dataset.buttonColor = "#6021b3";
            script.dataset.buttonTextColor = "#FFFFFF";
            script.dataset.title = "Assine nossa newsletter!";
            script.dataset.description = "Blog@Sailthrough";
            script.dataset.icon = "https://blog.sailthrough.com.br/content/images/size/w192h192/2024/07/roundlogo.png";
            script.dataset.site = "https://blog.sailthrough.com.br/";
            script.async = true;
            div.appendChild(script);
            container.appendChild(div);
        },
    },
}
</script>

<style scoped>
.newsletter-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.newsletter-signup {
    margin: 20px 0;
}
</style>