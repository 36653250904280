<template>
  <div class="blog-post-card1-blog-post-card" v-bind:class="rootClassName">
    <img :alt="imageAlt" :src="imageSrc" class="blog-post-card1-image" />
    <div class="blog-post-card1-container">
      <span class="blog-post-card1-text">{{ label }}</span>
      <span class="blog-post-card1-text1">{{ title }}</span>
      <span class="blog-post-card1-text2">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogPostCard1',
  props: {
    label: {
      type: String,
      default: 'ENTERPRISE',
    },
    rootClassName: String,
    profileSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&ixlib=rb-1.2.1&h=1200',
    },
    imageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1547841243-eacb14453cd9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIyfHxjaXR5fGVufDB8fHx8MTYyNjE4NjYxMg&ixlib=rb-1.2.1&w=1500',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur, adipiscing elit. Sed non volutpat turpis.  ​ Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem  ​​ ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    title: {
      type: String,
      default: 'Lorem ipsum dolor sit amet',
    },
    profileAlt: {
      type: String,
      default: 'profile',
    },
    author: {
      type: String,
      default: 'Jon Doe',
    },
    time: {
      type: String,
      default: '5 min read',
    },
  },
}
</script>

<style scoped>
.blog-post-card1-blog-post-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: 1400px;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.blog-post-card1-blog-post-card:hover {
  transform: scale(1.02);
}
.blog-post-card1-image {
  width: 100%;
  height: 1123px;
  object-fit: cover;
}
.blog-post-card1-container {
  display: flex;
  padding: 32px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.blog-post-card1-text {
  color: var(--dl-color-gray-500);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.blog-post-card1-text1 {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 32px;
}
.blog-post-card1-text2 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
  margin-bottom: 32px;
}

.blog-post-card1-root-class-name2 {
  width: 100%;
  height: 100%;
}
.blog-post-card1-root-class-name3 {
  width: 100%;
  height: 100%;
}

.blog-post-card1-root-class-name1 {
  width: 100%;
  height: 100%;
}
.blog-post-card1-root-class-name5 {
  width: 100%;
  height: 100%;
}
@media(max-width: 991px) {
  .blog-post-card1-image {
    height: 350px;
  }
}
</style>
