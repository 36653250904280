import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Pricing from './views/pricing'
import Landing from './views/landing'
import Home from './views/home'
import PrivacyPolicy from './views/privacy-policy'
import Newsletter from './views/newsletter'

import NotFound from './views/not-found'

import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Pricing',
      path: '/pricing',
      component: Pricing,
    },
    {
      name: 'Landing',
      path: '/landing',
      component: Landing,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Privacy-Policy',
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      name: 'Newsletter',
      path: '/newsletter',
      component: Newsletter,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
    {
      name: 'Chat',
      path: '/chat',
      beforeEnter() {
        window.location.href = 'https://wa.me/message/B73ZNNAZQRFHH1';
      }
    }
  ],
})
