<template>
  <div class="home-container">
    <div data-role="Header" class="home-header">
      <nav class="home-nav">
        <div class="home-container05">
          <router-link to="/" class="home-navlink Large">SailThrough</router-link>
          <div class="home-menu">
            <router-link to="/landing" class="home-navlink1 Large">
              Sobre
            </router-link>
            <a href="https://billing.stripe.com/p/login/9AQcN62gy1ky5t6fYY" target="_blank" rel="noreferrer noopener"
              class="home-link">
              <span>Área do Assinante</span>
              <br />
            </a>
            <router-link to="/pricing" class="home-navlink2">
              <span>Preços</span>
              <br />
            </router-link>
            <router-link to="/pricing" class="home-navlink3">Preços</router-link>
          </div>
          <div class="home-container06">
            <div class="home-container07">
              <a href="https://wa.me/351931196803" target="_blank" rel="noreferrer noopener" class="home-link1">
                <primary-pink-button button="Inscrição" class="home-component"></primary-pink-button>
              </a>
            </div>
            <div data-role="BurgerMenu" class="home-burger-menu">
              <svg viewBox="0 0 1024 1024" class="home-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <div class="home-hero">
      <div class="home-container08"></div>
      <div class="home-container09">
        <div class="home-card">
          <h1 class="home-text04">
            <span class="HeadingOne">SailThrough</span>
            <br />
          </h1>
          <h1 class="home-text07">   Where english is a breeze</h1>
          <span class="home-text08">
            Um curso único de Inglês onde você é encorajado a viver o segundo
            idioma.
          </span>
          <div class="home-container10">
            <div class="home-container11">
              <a href="https://wa.me/351931196803" target="_blank" rel="noreferrer noopener" class="home-link2">
                <primary-pink-button button="Inscreva-se" class="home-component01"></primary-pink-button>
              </a>
            </div>
            <a href="#knowmore" class="home-link3">
              <outline-gray-button button="Saiba mais" class="home-component02"></outline-gray-button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="home-testimonial">
      <div class="home-container12">
        <h1 class="home-text09">
          <span>O que nossos alunos dizem</span>
          <br />
        </h1>
        <div class="home-container13">
          <testimonial-card1 name="Júlia Santos" role="Inglês A1"
            quote="Muito boa a aula. Material excelente e professora muito legal. Tô conseguindo evoluir muito tanto na gramática quanto na pronúncia"
            pictureSrc="https://imgur.com/U2EV3vU.jpg" rootClassName="rootClassName2"></testimonial-card1>
          <testimonial-card1 name="Mayra Bastos" role="Inglês A1"
            quote="É uma professora incrível, faço aula há alguns meses, eu era nível zero e a minha evolução é nítida😍 simplesmente amo!"
            pictureSrc="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwebstockreview.net%2Fimages%2Fclipart-woman-cellphone-19.png&amp;f=1&amp;nofb=1&amp;ipt=a179a5c911ea76b25050f2c4044a554a10e99169e1c502f07e9249a031d2e688&amp;ipo=images"
            rootClassName="rootClassName"></testimonial-card1>
          <testimonial-card1 name="Sara Montês" role="Inglês A1"
            quote="As aulas são muito interativas e ajustam-se às nossas necessidades! Comecei com o inglês do 0 e sinto que evoluí bastante. Estas sessões têm me ajudado a ganhar confiança e à vontade na língua. Recomendo!"
            pictureSrc="https://lh3.googleusercontent.com/a-/ALV-UjXYHz0VX80Zp2skJvPtbx-gpicbtyaqT4ND9itf236Yt5w=w60-h60-p-rp-mo-br100"
            rootClassName="rootClassName1"></testimonial-card1>
        </div>
      </div>
    </div>
    <div id="knowmore" class="home-features">
      <h1 class="home-text12">
        <span>What floats our boat?</span>
        <br />
        <span class="home-text15">O que diferencia o SailThrough do resto.</span>
        <br />
      </h1>
      <div class="home-separator"></div>
      <div class="home-container14">
        <div class="home-container15">
          <feature-card41 title="We Got Ye"
            description="Uma hora por mês dedicada à você, para tirar duvidas ou praticar one-to-one com um de nossos professores."
            rootClassName="rootClassName1"></feature-card41>
          <feature-card41 title="Up Close and Personal"
            description="Com turmas de até 4 alunos, nossos professores tem mais flexibilidade para interagir diretamente com você"
            rootClassName="rootClassName4"></feature-card41>
          <feature-card41 title="Your Personal Time Machine"
            description="Todas as aulas são gravadas e enviadas para as turmas, assim você não perde nada!"
            rootClassName="rootClassName3"></feature-card41>
          <feature-card41 title="The One and Only" description="Material exclusivo e personalizado incluso por curso."
            rootClassName="rootClassName2"></feature-card41>
        </div>
      </div>
    </div>
    <!--
    <div class="home-blog">
      <div class="home-container16">
        <a href="https://www.linkedin.com/in/thais-frota-8957b5221/" target="_blank" rel="noreferrer noopener"
          class="home-link4">
          <blog-post-card1 label="Lead Teacher" title="Thais Frota" imageSrc="https://i.imgur.com/XRURnEx.png"
            description="Eu sou a Thais, sou prof. de inglês formada pela Universidade Federal do Ceará e dou aula de inglês há quatro anos. Também sou completamente apaixonada por literatura inglesa, gatos, aquarelas e Nescau!"
            rootClassName="rootClassName3" class="home-component10"></blog-post-card1>
        </a>
      </div>
      <div class="home-container17">
        <a href="https://www.linkedin.com/in/ahappyoreo/" target="_blank" rel="noreferrer noopener" class="home-link5">
          <blog-post-card1 label="Tech Nerd" title="Davi Sales" imageSrc="/img-20210101-wa0001~2-1500w.jpg"
            description="Sou um entusiasta apaixonado por UX Design e Node. Enquanto muitos se dedicam a uma vida de glamour e fama, eu encontro minha alegria nos detalhes meticulosos do design de experiência do usuário."
            rootClassName="rootClassName2" class="home-component11"></blog-post-card1>
        </a>
      </div>
      <div class="home-container18"></div>
    </div>
    -->
    <div id="newsletter-signup"
      style="min-height: 58px; max-width: 440px; width: 100%; position: relative; margin: 0 auto 30px; padding-top: 25px;">
    </div>
    <footer-gray3 rootClassName="footer-gray3-root-class-name"></footer-gray3>
    <div>
      <div class="home-container20">
        <DangerousHTML html="<!-- Google Tag Manager (noscript) -->
        <noscript><iframe src='https://www.googletagmanager.com/ns.html?id=GTM-M33TPLBS'
        height='0' width='0' style='display:none;visibility:hidden'></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->">
        </DangerousHTML>
      </div>
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'


import PrimaryPinkButton from '../components/primary-pink-button'
import OutlineGrayButton from '../components/outline-gray-button'
import TestimonialCard1 from '../components/testimonial-card1'
import FeatureCard41 from '../components/feature-card41'
import BlogPostCard1 from '../components/blog-post-card1'
import FooterGray3 from '../components/footer-gray3'
import chatWoo from '../components/chatWoo'

export default {
  name: 'Home',
  props: {},
  components: {
    DangerousHTML,
    PrimaryPinkButton,
    OutlineGrayButton,
    TestimonialCard1,
    FeatureCard41,
    BlogPostCard1,
    FooterGray3,
    chatWoo,
  },
  metaInfo: {
    title: 'SailThrough English',
    meta: [
      {
        property: 'og:title',
        content: 'SailThrough English',
      },
      {
        property: 'og:description',
        content: 'Where English is a Breeze.',
      },
    ],
  },
  data() {
    return {
      showChatWoo: true,
      expandChatWoo: false,
    };
  },
  mounted() {
    this.loadNewsletterSignupScript();
    setTimeout(() => {
      this.expandChatWoo = true;
      console.log('Timer expired, expandChatWoo set to:', this.expandChatWoo);
    }, 5000);
  },
  methods: {
    loadNewsletterSignupScript() {
      // Remove any existing content inside the newsletter-signup div
      const container = document.getElementById('newsletter-signup');
      container.innerHTML = '';

      // Create the new div with the specified style and script
      const div = document.createElement('div');
      div.style.height = '40vh';
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js';
      script.dataset.backgroundColor = "#ffffff";
      script.dataset.textColor = "#000000";
      script.dataset.buttonColor = "#6021b3";
      script.dataset.buttonTextColor = "#FFFFFF";
      script.dataset.title = "Assine nossa newsletter!";
      script.dataset.description = "Blog@Sailthrough";
      script.dataset.icon = "https://blog.sailthrough.com.br/content/images/size/w192h192/size/w256h256/2024/07/roundlogo.png";
      script.dataset.site = "https://blog.sailthrough.com.br/";
      script.async = true;

      // Append the script to the div, and the div to the container
      div.appendChild(script);
      container.appendChild(div);
    },
  },
  // Other component options
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-container02 {
  display: contents;
}

.home-container04 {
  display: contents;
}

.home-header {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  display: flex;
  z-index: 100;
  position: fixed;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.home-nav {
  flex: 0 0 auto;
  color: var(--dl-color-secondary-600);
  width: 100%;
  height: 60px;
  display: flex;
  z-index: 221;
  flex-wrap: wrap;
  box-shadow: 0 .25rem .375rem -.0625rem hsla(0, 0%, 8%, .12), 0 .125rem .25rem -.0625rem hsla(0, 0%, 8%, .07) !important;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: var(--dl-radius-radius-radius40);
  padding-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  backdrop-filter: saturate(200%) blur(30px);
  justify-content: space-between;
  background-color: hsla(0, 0%, 100%, .8) !important;
}

.home-container05 {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-navlink {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.home-navlink1 {
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.home-navlink1:hover {
  color: var(--dl-color-gray-500);
}

.home-link {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.home-link:hover {
  color: var(--dl-color-gray-500);
}

.home-navlink2 {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.home-navlink2:hover {
  color: var(--dl-color-gray-500);
}

.home-navlink3 {
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}

.home-navlink3:hover {
  color: var(--dl-color-gray-500);
}

.home-container06 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-container07 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-link1 {
  display: contents;
}

.home-component {
  text-decoration: none;
}

.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-icon {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}

.home-hero {
  width: 100%;
  display: flex;
  max-width: 1320px;
  align-items: center;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}

.home-container08 {
  top: 6rem;
  left: 43rem;
  right: 1rem;
  width: 703px;
  height: 943px;
  margin: auto;
  display: flex;
  z-index: 220;
  position: absolute;
  background-size: cover;
  background-image: url("/566-st-paul-s-cathedral-london-removebg-preview-434x575-1400w.png");
  background-position: right;
  border-bottom-left-radius: 10rem;
}

.home-container09 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1320px;
  min-height: 85vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-card {
  flex: 0 0 auto;
  width: 60%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 50;
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, .15), 0 8px 9px -5px hsla(0, 0%, 8%, .06) !important;
  margin-top: var(--dl-space-space-tripleunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius1);
  flex-direction: column;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, .8);
}

.home-text04 {
  text-align: center;
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-text07 {
  color: rgb(120, 134, 152);
  font-size: 1.2rem;
  text-align: center;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: var(--dl-space-space-unitandahalfunit);
  text-transform: none;
  text-decoration: none;
}

.home-text08 {
  color: var(--dl-color-secondary-600);
  margin-right: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-tripleunit);
}

.home-container10 {
  display: flex;
  margin-top: var(--dl-space-space-unitandahalfunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-container11 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: row;
}

.home-link2 {
  display: contents;
}

.home-component01 {
  text-decoration: none;
}

.home-link3 {
  display: contents;
}

.home-component02 {
  text-decoration: none;
}

.home-testimonial {
  width: 100%;
  display: flex;
  justify-content: center;
}

.home-container12 {
  width: 100%;
  display: flex;
  padding: 48px;
  z-index: 99;
  position: relative;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text09 {
  color: var(--dl-color-gray-black);
  align-self: flex-start;
  text-align: left;
  padding-bottom: var(--dl-space-space-tripleunit);
}

.home-container13 {
  width: 100%;
  display: grid;
  grid-gap: 32px;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
}

.home-features {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  flex-direction: column;
}

.home-text12 {
  font-size: 3rem;
  margin-bottom: 32px;
}

.home-text15 {
  font-size: 1.3rem;
  line-height: 1;
}

.home-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}

.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 32px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-container15 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.home-blog {
  width: 100%;
  display: flex;
  padding: 48px;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
}

.home-container16 {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 3px;
}

.home-link4 {
  display: contents;
}

.home-component10 {
  text-decoration: none;
}

.home-container17 {
  display: flex;
  align-items: center;
  margin-right: 32px;
  justify-content: center;
}

.home-link5 {
  display: contents;
}

.home-component11 {
  text-decoration: none;
}

.home-container18 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-container20 {
  display: contents;
}

@media(max-width: 1600px) {
  .home-menu {
    z-index: 600;
  }

  .home-container06 {
    z-index: 600;
  }

  .home-container08 {
    top: 6rem;
    left: 50rem;
    height: 911px;
  }

  .home-testimonial {
    background-color: #ffffff;
  }

  .home-text09 {
    color: #000000;
    padding-bottom: var(--dl-space-space-tripleunit);
  }
}

@media(max-width: 1200px) {
  .home-container08 {
    top: 12rem;
    left: 30rem;
    right: var(--dl-space-space-halfunit);
    margin: auto;
    z-index: 1;
  }

  .home-card {
    opacity: 0.9;
    z-index: 100;
  }

  .home-text09 {
    align-self: flex-start;
  }
}

@media(max-width: 991px) {
  .home-container08 {
    top: 14rem;
    right: 5rem;
    width: 483px;
    height: 739px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .home-container09 {
    align-items: flex-start;
  }

  .home-card {
    width: 663px;
    margin-right: 35px;
  }

  .home-text09 {
    text-align: center;
  }

  .home-container13 {
    grid-template-columns: 1fr;
  }

  .home-features {
    align-items: center;
  }

  .home-container14 {
    flex-direction: column;
  }

  .home-blog {
    flex-direction: column;
  }

  .home-container16 {
    margin-right: 0px;
    margin-bottom: 48px;
  }

  .home-container17 {
    margin-right: 0px;
    margin-bottom: 48px;
  }
}

@media(max-width: 767px) {
  .home-menu {
    flex-direction: row;
  }

  .home-container08 {
    top: 148px;
    left: 0px;
    right: 0px;
    width: 535px;
    height: 720px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .home-card {
    width: 100%;
  }

  .home-container12 {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-features {
    padding-left: 32px;
    padding-right: 32px;
  }

  .home-text12 {
    text-align: center;
  }

  .home-container14 {
    flex-direction: column;
  }

  .home-blog {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media(max-width: 479px) {
  .home-container07 {
    display: none;
  }

  .home-hero {
    height: 100%;
    align-self: stretch;
    padding-top: var(--dl-space-space-sixunits);
    justify-content: space-between;
  }

  .home-container08 {
    top: var(--dl-space-space-sixunits);
    left: 0px;
    right: 0px;
    width: 100%;
    height: auto;
    margin: auto;
    display: none;
  }

  .home-container09 {
    width: 100%;
    height: 620px;
    min-height: auto;
    align-items: center;
    background-size: cover;
    pointer-events: none;
    background-image: url("/566-st-paul-s-cathedral-london-removebg-preview-434x575-1400w.png");
  }

  .home-card {
    width: 100%;
    padding: var(--dl-space-space-unit);
    align-self: flex-start;
  }

  .home-text08 {
    color: var(--dl-color-secondary-600);
    font-size: 20px;
  }

  .home-container10 {
    align-items: center;
    flex-direction: column;
  }

  .home-container11 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }

  .home-container12 {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .home-features {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }

  .home-container15 {
    grid-template-columns: 1fr;
  }

  .home-blog {
    padding-top: 32px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 32px;
  }
}
</style>
