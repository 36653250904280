<template>
  <div class="privacy-policy-container">
    <div data-role="Header" class="privacy-policy-header">
      <nav class="privacy-policy-nav">
        <div class="privacy-policy-container1">
          <router-link to="/" class="privacy-policy-navlink Large">
            SailThrough
          </router-link>
          <div class="privacy-policy-menu">
            <router-link to="/landing" class="privacy-policy-navlink1 Large">
              Sobre
            </router-link>
            <a
              href="https://billing.stripe.com/p/login/9AQcN62gy1ky5t6fYY"
              target="_blank"
              rel="noreferrer noopener"
              class="privacy-policy-link"
            >
              <span>Área do Assinante</span>
              <br />
            </a>
            <router-link to="/pricing" class="privacy-policy-navlink2">
              <span>Preços</span>
              <br />
            </router-link>
            <router-link to="/pricing" class="privacy-policy-navlink3">
              Preços
            </router-link>
          </div>
          <div class="privacy-policy-container2">
            <div class="privacy-policy-container3">
              <primary-pink-button
                button="Inscrição"
                class="privacy-policy-component"
              ></primary-pink-button>
            </div>
            <div data-role="BurgerMenu" class="privacy-policy-burger-menu">
              <svg viewBox="0 0 1024 1024" class="privacy-policy-icon">
                <path
                  d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
    </div>
    <h1 class="privacy-policy-text004">
      Política de Privacidade
      <span v-html="rawfvud"></span>
    </h1>
    <main id="privacypol" class="privacy-policy-container4">
      <span class="privacy-policy-text005">
        <span>Última Atualização: 8 de fevereiro de 2024</span>
        <br />
        <br />
        <span>
          Bem-vindo ao SailThrough English! Esta Política de Privacidade descreve
          como o SailThrough English coleta, usa, mantém e divulga informações
          coletadas de usuários (&quot;você&quot; ou &quot;usuários&quot;) do site
          https://sailthrough.com.br (&quot;Site&quot;) e quaisquer serviços
          relacionados fornecidos pelo SailThrough English (&quot;Serviço&quot;).
        </span>
        <br />
        <br />
        <span>Informações que Coletamos</span>
        <br />
        <br />
        <span>Informações Pessoais</span>
        <br />
        <br />
        <span>
          Quando você se registra em nosso Site ou se inscreve em nossos cursos,
          podemos coletar informações pessoais, como seu nome, endereço de e-mail,
          número de telefone e informações de faturamento. Coletamos essas
          informações apenas se você as fornecer voluntariamente.
        </span>
        <br />
        <br />
        <br />
        <span>Informações de Uso</span>
        <br />
        <br />
        <span>
          Também podemos coletar informações não pessoais sobre como você interage
          com nosso Site e Serviço. Isso pode incluir seu endereço IP, tipo de
          navegador, páginas visitadas e outras estatísticas de uso. Coletamos
          essas informações usando cookies e tecnologias de rastreamento
          semelhantes.
        </span>
        <br />
        <br />
        <br />
        <span>Como Usamos Suas Informações</span>
        <br />
        <br />
        <span>
          Podemos usar as informações que coletamos para diversos fins, incluindo:
        </span>
        <br />
        <br />
        <br />
        <span>Personalizar sua experiência e melhorar nosso Serviço.</span>
        <br />
        <br />
        <span>Processar pagamentos e inscrições.</span>
        <br />
        <br />
        <span>Comunicar-se com você sobre sua conta, cursos e promoções.</span>
        <br />
        <br />
        <span>
          Monitorar e analisar tendências, uso e atividades em nosso Site.
        </span>
        <br />
        <br />
        <span>
          Cumprir requisitos legais e proteger nossos direitos e interesses.
        </span>
        <br />
        <br />
        <span>Anonimato de Pagamento e Processamento de Pagamento</span>
        <br />
        <br />
        <span>Anonimato de Pagamento</span>
        <br />
        <br />
        <span>
          Entendemos a importância da privacidade quando se trata de informações
          de pagamento. O SailThrough English prioriza a segurança e o anonimato
          dos seus detalhes de pagamento. Não armazenamos nenhuma informação de
          cartão de crédito em nossos servidores. Todas as transações de pagamento
          são processadas com segurança por meio do nosso processador de
          pagamentos de terceiros confiável, Stripe.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Processamento de Pagamento pelo Stripe</span>
        <br />
        <br />
        <span>
          Utilizamos o Stripe como nossa plataforma de processamento de
          pagamentos. Quando você faz um pagamento por meio de nosso Serviço, suas
          informações de pagamento são criptografadas e transmitidas com segurança
          para o Stripe para processamento. O Stripe adere a padrões de segurança
          líderes do setor para garantir a proteção de suas informações de
          pagamento.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>
          Por favor, observe que, embora tomemos todas as precauções para proteger
          suas informações de pagamento, nenhum método de transmissão pela
          Internet ou armazenamento eletrônico é 100% seguro. Portanto, não
          podemos garantir segurança absoluta.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>
          Para obter mais informações sobre como o Stripe trata suas informações
          de pagamento, consulte a Política de Privacidade do Stripe.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Como Protegemos Suas Informações</span>
        <br />
        <br />
        <span>
          Levamos a segurança de suas informações pessoais a sério e tomamos
          precauções razoáveis para protegê-las. Utilizamos tecnologias de
          criptografia padrão do setor para proteger seus dados durante a
          transmissão e o armazenamento.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Compartilhando Suas Informações</span>
        <br />
        <br />
        <span>
          Não vendemos, trocamos ou alugamos suas informações pessoais para
          terceiros. No entanto, podemos compartilhar suas informações com
          prestadores de serviços de terceiros confiáveis que nos ajudam a operar
          nosso Site e fornecer nosso Serviço. Esses terceiros são obrigados a
          manter a confidencialidade de suas informações e são proibidos de
          usá-las para qualquer outro fim.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Suas Escolhas</span>
        <br />
        <br />
        <span>
          Você pode optar por não fornecer algumas informações, mas isso pode
          limitar sua capacidade de usar determinados recursos de nosso Serviço.
          Você também pode optar por não receber e-mails promocionais nossos
          seguindo as instruções de cancelamento de inscrição fornecidas no
          e-mail.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Alterações a Esta Política de Privacidade</span>
        <br />
        <br />
        <span>
          Podemos atualizar esta Política de Privacidade de tempos em tempos.
          Notificaremos você sobre quaisquer alterações publicando a nova Política
          de Privacidade nesta página. Recomenda-se que você revise esta Política
          de Privacidade periodicamente para quaisquer alterações.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Entre em Contato</span>
        <br />
        <br />
        <span>
          Se você tiver alguma dúvida ou preocupação sobre esta Política de
          Privacidade, não hesite em nos contatar pelo e-mail
          contact@sailthrough.com.br.
        </span>
        <br />
        <br />
        <br />
        <br />
        <span>Obrigado por escolher o SailThrough English!</span>
      </span>
    </main>
    <footer-gray3 rootClassName="footer-gray3-root-class-name4"></footer-gray3>
  </div>
</template>

<script>
import PrimaryPinkButton from '../components/primary-pink-button'
import FooterGray3 from '../components/footer-gray3'

export default {
  name: 'PrivacyPolicy',
  props: {},
  components: {
    PrimaryPinkButton,
    FooterGray3,
  },
  data() {
    return {
      rawfvud: ' ',
    }
  },
  metaInfo: {
    title: 'Privacy-Policy - SailThrough English',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy-Policy - SailThrough English',
      },
      {
        property: 'og:description',
        content: 'Where English is a Breeze.',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/09f000e7-ab0d-4836-ba24-3a9b40291860/1d2dd100-6c7e-4c7d-b25d-b8a45b16c37d?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.privacy-policy-header {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  display: flex;
  z-index: 100;
  position: fixed;
  max-width: 1320px;
  padding-left: var(--dl-space-space-unitandahalfunit);
  padding-right: var(--dl-space-space-unitandahalfunit);
  justify-content: center;
}
.privacy-policy-nav {
  flex: 0 0 auto;
  color: var(--dl-color-secondary-600);
  width: 100%;
  height: 60px;
  display: flex;
  z-index: 221;
  flex-wrap: wrap;
  box-shadow: 0 .25rem .375rem -.0625rem hsla(0,0%,8%,.12),0 .125rem .25rem -.0625rem hsla(0,0%,8%,.07)!important;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unitandahalfunit);
  border-radius: var(--dl-radius-radius-radius40);
  padding-right: var(--dl-space-space-unitandahalfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  backdrop-filter: saturate(200%) blur(30px);
  justify-content: space-between;
  background-color: hsla(0,0%,100%,.8)!important;
}
.privacy-policy-container1 {
  color: var(--dl-color-gray-black);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-navlink {
  color: var(--dl-color-secondary-700);
  font-weight: 600;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.privacy-policy-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.privacy-policy-navlink1 {
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.privacy-policy-navlink1:hover {
  color: var(--dl-color-gray-500);
}
.privacy-policy-link {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.privacy-policy-link:hover {
  color: var(--dl-color-gray-500);
}
.privacy-policy-navlink2 {
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.privacy-policy-navlink2:hover {
  color: var(--dl-color-gray-500);
}
.privacy-policy-navlink3 {
  display: none;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.privacy-policy-navlink3:hover {
  color: var(--dl-color-gray-500);
}
.privacy-policy-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-component {
  text-decoration: none;
}
.privacy-policy-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.privacy-policy-icon {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.privacy-policy-text004 {
  align-self: center;
  font-style: italic;
  font-weight: 700;
  padding-top: 8rem;
}
.privacy-policy-container4 {
  flex: 0 0 auto;
  height: 75%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-unit);
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.privacy-policy-text005 {
  align-self: center;
  font-style: normal;
  margin-top: 0%;
  text-align: left;
  font-weight: 700;
  margin-left: var(--dl-space-space-fourunits);
  padding-top: var(--dl-space-space-halfunit);
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: 0%;
  padding-bottom: var(--dl-space-space-halfunit);
}
@media(max-width: 1600px) {
  .privacy-policy-menu {
    z-index: 600;
  }
  .privacy-policy-container2 {
    z-index: 600;
  }
}
@media(max-width: 767px) {
  .privacy-policy-menu {
    flex-direction: row;
  }
  .privacy-policy-text005 {
    font-style: normal;
    font-weight: 700;
  }
}
@media(max-width: 479px) {
  .privacy-policy-container3 {
    display: none;
  }
  .privacy-policy-text005 {
    margin-left: var(--dl-space-space-unitandahalfunit);
    margin-right: var(--dl-space-space-unitandahalfunit);
  }
}
</style>
