<template>
    <div></div>
</template>

<script>
window.chatwootSettings = { "position": "right", "type": "expanded_bubble", "launcherTitle": "Converse Conosco" };
(function (d, t) {
    var BASE_URL = "https://app.chatwoot.com";
    var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
        window.chatwootSDK.run({
            websiteToken: 'jDqSr6KWDzowdh3KohmjPJ7Y',
            baseUrl: BASE_URL
        })
    }
})(document, "script");
</script>
