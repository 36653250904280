<template>
  <div class="feature-card41-feature-card" v-bind:class="rootClassName">
    <h2 class="feature-card41-text">{{ title }}</h2>
    <span class="feature-card41-text1">{{ description }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard41',
  props: {
    rootClassName: String,
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    description: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
    },
  },
}
</script>

<style scoped>
.feature-card41-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  max-width: 1400px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card41-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card41-text1 {
  color: var(--dl-color-gray-700);
}
.feature-card41-root-class-name {
  width: 100%;
}








@media(max-width: 479px) {
  .feature-card41-text {
    font-style: normal;
    font-weight: 600;
  }
}
</style>
