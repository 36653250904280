<template>
  <footer class="footer-gray3-footer" v-bind:class="rootClassName">
    <div class="footer-gray3-divider"></div>
    <div class="footer-gray3-container">
      <div class="footer-gray3-container1">
        <div class="footer-gray3-container2">
          <span class="footer-gray3-text">SailThrough English</span>
        </div>
        <div class="footer-gray3-container3">
          <div class="footer-gray3-container4">
            <span class="footer-gray3-text1 Large">Compania</span>
            <router-link to="/" class="footer-gray3-navlink Large">
              Sobre
            </router-link>
          </div>
          <div class="footer-gray3-container5">
            <span class="footer-gray3-text2">Ajuda e Suporte</span>
            <a
              href="mailto:contact@sailthrough.com.br?subject="
              class="footer-gray3-link Large"
            >
              Contato
            </a>
            <a
              href="https://sailthrough.com.br/privacy-policy#privacypol"
              class="footer-gray3-link1"
            >
              Política de Privacidade
              <span v-html="rawnfcs"></span>
            </a>
          </div>
        </div>
      </div>
      <span class="footer-gray3-text3 Small">
        <span>All rights reserved. Copyright © 2021 SailThrough English</span>
        <span>.</span>
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterGray3',
  props: {
    rootClassName: String,
    linkText: String,
  },
  data() {
    return {
      rawnfcs: ' ',
    }
  },
}
</script>

<style scoped>
.footer-gray3-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.footer-gray3-divider {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: row;
  background-image: linear-gradient(90deg,transparent,rgba(0,0,0,.4),transparent);
}
.footer-gray3-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  position: relative;
  max-width: 1320px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.footer-gray3-container1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-gray3-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-gray3-text {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-gray3-container3 {
  width: 268px;
  display: flex;
  align-self: center;
  justify-content: flex-end;
}
.footer-gray3-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-gray3-text1 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg,#7928ca,#ff0080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-gray3-navlink {
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.footer-gray3-container5 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.footer-gray3-text2 {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
  background-image: linear-gradient(310deg, rgb(121, 40, 202), rgb(255, 0, 128));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-gray3-link {
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.footer-gray3-link1 {
  margin-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.footer-gray3-text3 {
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
}






@media(max-width: 1600px) {
  .footer-gray3-root-class-name {
    background-color: transparent;
  }
  .footer-gray3-root-class-name1 {
    background-color: transparent;
  }
  .footer-gray3-root-class-name2 {
    background-color: transparent;
  }
  .footer-gray3-root-class-name3 {
    background-color: transparent;
  }
  .footer-gray3-root-class-name4 {
    background-color: transparent;
  }
}
@media(max-width: 991px) {
  .footer-gray3-container {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .footer-gray3-text {
    display: none;
  }
}
@media(max-width: 767px) {
  .footer-gray3-container {
    max-width: 100%;
  }
}
@media(max-width: 479px) {
  .footer-gray3-text {
    display: none;
  }
  .footer-gray3-container4 {
    align-self: center;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
